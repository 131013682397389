<template>
  <v-container>
    <v-app-bar
      dark
      color="#223855"
    >
      <v-spacer></v-spacer>
      <v-toolbar-title><v-icon large left>mdi-home</v-icon>Rental Calculator</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
</style>