<template>
  <v-container fluid>
    <v-card
      class="mx-auto ma-2"
      color="#26c6da"
      max-width="89%"
    >
      <v-stepper
        vertical
        v-model="getStep"                 
      >
        <step-property-address step="1"></step-property-address>
        <step-property-details step="2"></step-property-details>
        <step-rental-income step="3"></step-rental-income>
        <step-annual-operating-expenses step="4"></step-annual-operating-expenses>
        <step-purchase-information step="5"></step-purchase-information>
        <step-financing-costs step="6"></step-financing-costs>
        <step-finalize step="7"></step-finalize>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>

import StepPropertyAddress from './StepPropertyAddress';
import StepPropertyDetails from './StepPropertyDetails';
import StepRentalIncome from './StepRentalIncome';
import StepAnnualOperatingExpenses from './StepAnnualOperatingExpenses';
import StepPurchaseInformation from './StepPurchaseInformation';
import StepFinancingCosts from './StepFinancingCosts';
import StepFinalize from './StepFinalize';

export default {
  components: {
    StepPropertyAddress,
    StepPropertyDetails,
    StepRentalIncome,
    StepAnnualOperatingExpenses,
    StepPurchaseInformation,
    StepFinancingCosts,
    StepFinalize
  },
  data() {
    return {}
  },
  computed: {
    getStep: function() {
      return this.$store.getters.getStep();
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>