<template>
  <v-row>
    <v-card 
      max-width="600"
      class="ma-6"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="purchasePrice"
                label="Purchase Price"
                type="number"
                prefix="$"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="purchaseClosingCosts"
                label="Purchase Closing Costs"
                type="number"
                value="0"
                prefix="%"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="closingCosts"
                label="Purchase Closing Costs"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="estimatedRenovationCost"
                label="Estimated Renovation Cost"
                type="number"
                value="0"
                prefix="$"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="totalHardCosts"
                label="Total Hard Costs"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="setStep(6)"
        >
          Continue
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  computed: {
    purchasePrice: {
      get() {
        return this.$store.getters.getPurchasePrice();
      },
      set(value) {
        this.$store.commit('setPurchasePrice', parseFloat(value));
      }
    },
    purchaseClosingCosts: {
      get() {
        return this.$store.getters.getPurchaseClosingCosts();
      },
      set(value) {
        this.$store.commit('setPurchaseClosingCosts', parseFloat(value));
      }
    },
    estimatedRenovationCost: {
      get() {
        return this.$store.getters.getEstimatedRenovationCost();
      },
      set(value) {
        this.$store.commit('setEstimatedRenovationCost', parseFloat(value));
      }
    },
    closingCosts: {
      get() {
        const purchasePrice = this.$store.getters.getPurchasePrice() || 0;
        const estimatedRenovationCost = this.$store.getters.getEstimatedRenovationCost() || 0;
        const purchaseClosingCosts = this.$store.getters.getPurchaseClosingCosts() || 0;

        return (purchasePrice + estimatedRenovationCost) * (purchaseClosingCosts / 100);
      }
    },
    totalHardCosts: {
      get() {
        const purchasePrice = this.$store.getters.getPurchasePrice() || 0;
        const estimatedRenovationCost = this.$store.getters.getEstimatedRenovationCost() || 0;
        const purchaseClosingCosts = this.$store.getters.getPurchaseClosingCosts() || 0;

        return purchasePrice + estimatedRenovationCost + 
          ((purchasePrice + estimatedRenovationCost) * (purchaseClosingCosts / 100));
      }
    },
  },
  methods: {
    setStep: function(step) {
      this.$store.commit('setStep', step);
    },
  },
}
</script>

<style scoped>

</style>