<template>
  <v-row>
    <v-card 
      max-width="600"
      class="ma-6"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="downPaymentPercent"
                label="Down Payment"
                type="number"
                prefix="%"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="downPayment"
                label="Down Payment"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="totalLoanAmount"
                label="Total Loan Amount"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="pointsToLenderPercent"
                label="Points to Lender"
                type="number"
                value="0"
                prefix="%"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="pointsToLender"
                label="Points to Lender"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="annualInterestRate"
                label="Annual Interest Rate"
                type="number"
                value="0"
                prefix="%"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="interestRate"
                label="Monthly Interest Rate"
                prefix="%"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="amortizationYears"
                label="Amortization Years"
                type="number"
                value="0"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="numberOfPayments"
                label="Number Of Payments"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="monthlyPayment"
                label="Monthly Payment"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="annualDebtService"
                label="Annual Debt Service"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="setStep(7)"
        >
          Continue
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  computed: {
    downPaymentPercent: {
      get() {
        return this.$store.getters.getDownPayment();
      },
      set(value) {
        this.$store.commit('setDownPayment', parseFloat(value));
      }
    },
    pointsToLenderPercent: {
      get() {
        return this.$store.getters.getPointsToLender();
      },
      set(value) {
        this.$store.commit('setPointsToLender', parseFloat(value));
      }
    },
    annualInterestRate: {
      get() {
        return this.$store.getters.getInterestRate();
      },
      set(value) {
        this.$store.commit('setInterestRate', parseFloat(value));
      }
    },
    amortizationYears: {
      get() {
        return this.$store.getters.getAmortizationYears();
      },
      set(value) {
        this.$store.commit('setAmortizationYears', parseFloat(value));
      }
    },
    downPayment: {
      get() {
        const purchasePrice = this.$store.getters.getPurchasePrice() || 0;
        const estimatedRenovationCost = this.$store.getters.getEstimatedRenovationCost() || 0;
        const downPayment = this.$store.getters.getDownPayment() || 0;

        const result = (purchasePrice + estimatedRenovationCost) * (downPayment / 100);
        return result;
      },
      set(value) {
        this.$store.commit('setDownPayment',  parseFloat(value));
      }
    },
    totalLoanAmount: {
      get() {
        return this.getTotalLoanAmount();
      },
      set(value) {
        this.$store.commit('setTotalLoanAmount', parseFloat(value));
      }
    },
    pointsToLender: {
      get() {
        const purchasePrice = this.$store.getters.getPurchasePrice() || 0;
        const estimatedRenovationCost = this.$store.getters.getEstimatedRenovationCost() || 0;
        const purchaseClosingCosts = this.$store.getters.getPurchaseClosingCosts() || 0;
        const downPayment = this.$store.getters.getDownPayment() || 0;
        const pointsToLenderPercent = this.$store.getters.getPointsToLender() || 0;

        const totalHardCosts = purchasePrice + estimatedRenovationCost + 
          ((purchasePrice + estimatedRenovationCost) * (purchaseClosingCosts / 100));

        const result = (pointsToLenderPercent / 100) * (totalHardCosts - (purchasePrice + estimatedRenovationCost) * (downPayment / 100));

        return result;
      }
    },
    interestRate: {
      get() {
        return this.$store.getters.getInterestRate() / 12;
      }
    },
    numberOfPayments: {
      get() {
        return this.$store.getters.getAmortizationYears() * 12;
      }
    },
    monthlyPayment: {
      get() {
        const monthlyPayment = this.getMonthlyPayment();
        if (monthlyPayment > 0) {
          return monthlyPayment;
        }
        return 0;
      },
      set(value) {
        this.$store.commit('setMonthlyPayment', parseFloat(value));
      }
    },
    annualDebtService: {
      get() {
        const monthlyPayment = this.getMonthlyPayment();
        if (monthlyPayment > 0) {
          return monthlyPayment * 12;
        }
        return 0;
      }
    },
  },
  methods: {
    setStep: function(step) {
      this.$store.commit('setStep', step);
    },
    getAnnualInterestRate: function() {
      return this.$store.getters.getInterestRate() / 100;
    },
    getTotalLoanAmount: function() {
      const purchasePrice = this.$store.getters.getPurchasePrice() || 0;
      const estimatedRenovationCost = this.$store.getters.getEstimatedRenovationCost() || 0;
      const purchaseClosingCosts = this.$store.getters.getPurchaseClosingCosts() || 0;

      const totalHardCosts = purchasePrice + estimatedRenovationCost + 
        ((purchasePrice + estimatedRenovationCost) * (purchaseClosingCosts / 100));

      const downPayment = this.$store.getters.getDownPayment() || 0;

      const result = totalHardCosts - (purchasePrice + estimatedRenovationCost) * (downPayment / 100);
      return result;
    },
    getMonthlyPayment: function() {
      const interestRate = this.getAnnualInterestRate() / 12;
      const payments = this.$store.getters.getAmortizationYears() * 12;
      const totalLoanAmount = this.getTotalLoanAmount();

      const result = this.payment(interestRate, payments, totalLoanAmount);

      return result;
    },
    payment: (ir, np, pv, fv, type) => {
      /*
      * ir   - interest rate per month
      * np   - number of periods (months)
      * pv   - present value
      * fv   - future value
      * type - when the payments are due:
      *        0: end of the period, e.g. end of month (default)
      *        1: beginning of period
      */
      let pmt, pvif;
      fv || (fv = 0);
      type || (type = 0);
      if (ir === 0) {
        return -(pv + fv) / np;
      }
      pvif = Math.pow(1 + ir, np);
      // pmt = - ir * (pv * pvif + fv) / (pvif - 1);
      pmt = ir * (pv * pvif + fv) / (pvif - 1);
      if (type === 1) {
        pmt /= (1 + ir);
      }
      return pmt;
    },
  },
}
</script>

<style scoped>

</style>