<template>
  <div>
    <v-stepper-step
      :complete="getStep > step"
      :step="step"
    >
      <v-btn text  @click="setStep(step)">
        Financing Costs
      </v-btn>
    </v-stepper-step>

    <v-stepper-content :step="step">
      <dialog-financing-costs></dialog-financing-costs>
    </v-stepper-content>    
  </div>
</template>

<script>

import DialogFinancingCosts from './DialogFinancingCosts';

export default {
  components: {
    DialogFinancingCosts
  },
  props: ['step'],
  data() {
    return {
    }
  },
  computed: {
    getStep: function() {
      return this.$store.getters.getStep();
    }
  },
  methods: {
    setStep: function(step) {
      this.$store.dispatch('setStep', step);
    },
    pmt: function(ir, np, pv, fv, type) {
      /*
      * ir   - interest rate per month
      * np   - number of periods (months)
      * pv   - present value
      * fv   - future value
      * type - when the payments are due:
      *        0: end of the period, e.g. end of month (default)
      *        1: beginning of period
      */
      let pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) {
        return -(pv + fv) / np;
      }

      pvif = Math.pow(1 + ir, np);
      pmt = - ir * (pv * pvif + fv) / (pvif - 1);

      if (type === 1) {
        pmt /= (1 + ir);
      }

      return pmt;
    }
  }
}
</script>

<style scoped>

</style>