<template>
    <div>
      <header-bar></header-bar>
      <input-stepper></input-stepper>
    </div>
</template>

<script>
import HeaderBar from './HeaderBar';
import InputStepper from './InputStepper';

export default {
  components: {
    HeaderBar,
    InputStepper,
  }
}
</script>

<style scoped>

</style>>
