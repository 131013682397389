<template>
  <v-row>
    <v-card 
      max-width="600"
      class="ma-6"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="grossMonthlyRentalIncome"
                label="Gross Monthly Rental Income*"
                type="number"
                prefix="$"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="grossAnnualRentalIncome"
                label="Gross Annual Rental Income"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="annualVacancyPercentage"
                label="Vacancy*"
                type="number"
                value="0"
                prefix="%"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="annualVacancy"
                label="Annual Vacancy"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="rentalIncome"
                label="Net Rental Income"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="setStep(4)"
        >
          Continue
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  computed: {
    grossMonthlyRentalIncome: {
      get() {
        return this.$store.getters.getGrossMonthlyRentalIncome();
      },
      set(value) {
        this.$store.commit('setGrossMonthlyRentalIncome', parseFloat(value));
      }
    },
    annualVacancyPercentage: {
      get() {
        return this.$store.getters.getAnnualVacancyPercentage();
      },
      set(value) {
        this.$store.commit('setAnnualVacancyPercentage', parseFloat(value));
      }
    },
    grossAnnualRentalIncome: {
      get() {
        return this.$store.getters.getGrossAnnualRentalIncome();
      },
    },
    annualVacancy: {
      get() {
        return this.$store.getters.getAnnualVacancy();
      },
    },
    rentalIncome: {
      get() {
        return this.$store.getters.getRentalIncome();
      },
    },
  },
  methods: {
    setStep: function(step) {
      this.$store.commit('setStep', step);
    },
  },
}
</script>

<style scoped>

</style>