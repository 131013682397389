<template>
  <div>
    <v-stepper-step
      :step="step"
    >
      <v-btn text  @click="setStep(step)">
        Finalize
      </v-btn>
    </v-stepper-step>

    <v-stepper-content :step="step">
      <v-card
        color="grey lighten-1"
        class="mb-12"
        height="200px"
      ></v-card>
      <v-btn
        color="primary"
        @click="setStep(parseInt(1))"
      >
        Continue
      </v-btn>
    </v-stepper-content>
  </div>
</template>

<script>

export default {
  props: ['step'],
  data() {
    return {
    }
  },
  computed: {
    getStep: function() {
      return this.$store.getters.getStep();
    }
  },
  methods: {
    setStep: function(step) {
      this.$store.commit('setStep', step);
    }
  }
}
</script>

<style scoped>

</style>