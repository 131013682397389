<template>
  <v-app>
    <home></home>
  </v-app>
</template>

<script>
import Home from './components/Home';

export default {
  components: {
    Home
  },
  name: 'App',

  data: () => ({
  }),
};
</script>

<style scoped>
</style>