<template>
  <div>
    <v-stepper-step
      :complete="getStep > step"
      :step="step"
    >
      <v-btn text  @click="setStep(step)">
        Purchase Information
      </v-btn>
    </v-stepper-step>

    <v-stepper-content :step="step">
      <dialog-purchase-information></dialog-purchase-information>
      <!-- <v-card
        color="grey lighten-1"
        class="mb-12"
        height="200px"
      ></v-card>
      <v-btn
        color="primary"
        @click="setStep(parseInt(step) + 1)"
      >
        Continue
      </v-btn> -->
    </v-stepper-content>
  </div>
</template>

<script>

import DialogPurchaseInformation from './DialogPurchaseInformation';

export default {
  components: {
    DialogPurchaseInformation,
  },
  props: ['step'],
  data() {
    return {
    }
  },
  computed: {
    getStep: function() {
      return this.$store.getters.getStep();
    }
  },
  methods: {
    setStep: function(step) {
      this.$store.dispatch('setStep', step);
    }
  }
}
</script>

<style scoped>

</style>