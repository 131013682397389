<template>
  <v-row>
    <v-card
      max-width="600"
      class="ma-6"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="numberOfUnits"
                label="Number of units*"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="currentStatus"
                :items="['Negotiating', 'Offer Accepted', 'Purchase & Sale', 'Agreement Signed']"
                label="Current Status*"
                required
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-menu
                v-model="closingDate"
                :close-on-content-click="false"
                :nudge-right="20"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Closing Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="estimatedValue"
                label="Estimated After Repair Value"
                value="0.00"
                type="number"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="marketCapRate"
                label="Market Cap Rate"
                type="number"
                value="0"
                suffix="%"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="setStep(3)"
        >
          Continue
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
    }
  },
  computed: {
    numberOfUnits: {
      set(data) {
        this.$store.commit('setNumberOfUnits', data);
      },
      get() {
        return this.$store.getters.getNumberOfUnits();
      }
    },
    currentStatus: {
      set(data) {
        this.$store.commit('setCurrentStatus', data);
      },
      get() {
        return this.$store.getters.getCurrentStatus();
      }
    },
    closingDate: {
      set(data) {
        this.$store.commit('setClosingDate', data);
      },
      get() {
        return this.$store.getters.getClosingDate();
      }
    },
    estimatedValue: {
      set(data) {
        this.$store.commit('setEstimatedValue', data);
      },
      get() {
        return this.$store.getters.getEstimatedValue();
      }
    },
    marketCapRate: {
      set(data) {
        this.$store.commit('setMarketCapRate', data);
      },
      get() {
        return this.$store.getters.getMarketCapRate();
      }
    },
  },
  methods: {
    setStep: function(step) {
      this.$store.commit('setStep', step);
    },
  },
}
</script>

<style scoped>

</style>