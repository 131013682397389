import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    step: 1,
    address: {
      street: '',
      city: '',
      state: '',
      zip: ''
    },
    details: {
      numberOfUnits: 0,
      currentStatus: '',
      closingDate: '',
      estimatedValue: 0,
      marketCapRate: 0,
    },
    rentalIncome: {
      grossMonthlyRentalIncome: 0,
      annualVacancyPercentage: 0,
      grossAnnualRentalIncome: 0,
      annualVacancy: 0,
      rentalIncome: 0,
    },
    annualOperatingExpenses: {
      realEstateTaxes: 0,
      insurance: 0,
      waterAndSewer: 0,
      publicElectric: 0,
      otherExpenses: 0,
      percentOfNetRentalIncome: 0,
    },
    purchaseInfo: {
      purchasePrice: 0,
      purchaseClosingCosts: 0,
      estimatedRenovationCost: 0,
    },
    financingCosts: {
      downPayment: 0,
      pointsToLender: 0,
      interestRate: 0,
      amortizationYears: 0,
      totalLoanAmount: 0,
      monthlyPayment: 0
    },
  },
  getters: {
    getStep: state => () => {
      return state.step;
    },
    // Property Address
    getStreetAddress: state => () => {
      return state.address.street;
    },
    getCityAddress: state => () => {
      return state.address.city;
    },
    getStateAddress: state => () => {
      return state.address.state;
    },
    getZipAddress: state => () => {
      return state.address.zip;
    },    
    // Property Details
    getNumberOfUnits: state => () => {
      return state.details.numberOfUnits;
    },
    getCurrentStatus: state => () => {
      return state.details.currentStatus;
    },
    getClosingDate: state => () => {
      return state.details.closingDate;
    },
    getEstimatedValue: state => () => {
      return state.details.estimatedValue;
    },
    getMarketCapRate: state => () => {
      return state.details.marketCapRate;
    },
    // Rental Income
    getGrossMonthlyRentalIncome: state => () => {
      return state.rentalIncome.grossMonthlyRentalIncome;
    },
    getGrossAnnualRentalIncome: state => () => {
      if (state.rentalIncome.grossMonthlyRentalIncome > 0) {
        return state.rentalIncome.grossMonthlyRentalIncome * 12;
      }
      return 0;
    },
    getAnnualVacancyPercentage: state => () => {
      return state.rentalIncome.annualVacancyPercentage;
    },
    getAnnualVacancy: state => () => {
      const grossMonthlyIncome = state.rentalIncome.grossMonthlyRentalIncome || 0;
      const annualVacancyPercent = state.rentalIncome.annualVacancyPercentage || 0;
      if (grossMonthlyIncome > 0) {
        return (grossMonthlyIncome * 12) * (annualVacancyPercent / 100) * (-1);
      }
      return 0;
    },
    getRentalIncome: state => () => {
      const grossMonthlyIncome = state.rentalIncome.grossMonthlyRentalIncome || 0;
      const annualVacancyPercent = state.rentalIncome.annualVacancyPercentage || 0;
      if (grossMonthlyIncome > 0) {
          const grossAnnualRentalIncome = grossMonthlyIncome * 12;
          const annualVacancyPercentAdjusted = annualVacancyPercent / 100;  
          return grossAnnualRentalIncome + ((grossAnnualRentalIncome * annualVacancyPercentAdjusted) * -1);
      }
      return 0;
    },
    // Annual operating expenses
    getRealEstateTaxes: state => () => {
      return state.annualOperatingExpenses.realEstateTaxes;
    },
    getInsurance: state => () => {
      return state.annualOperatingExpenses.insurance;
    },
    getWaterAndSewer: state => () => {
      return state.annualOperatingExpenses.waterAndSewer;
    },
    getPublicElectric: state => () => {
      return state.annualOperatingExpenses.publicElectric;
    },
    getOtherExpenses: state => () => {
      return state.annualOperatingExpenses.otherExpenses;
    },
    getPercentOfNetRentalIncome: state => () => {
      return state.annualOperatingExpenses.percentOfNetRentalIncome;
    },
    getRepairsAndMaintenance: state => () => {
      const grossMonthlyRentalIncome = state.rentalIncome.grossMonthlyRentalIncome || 0;
      const annualVacancyPercentage = state.rentalIncome.annualVacancyPercentage || 0;
      const percentOfNetRentalIncome = state.annualOperatingExpenses.percentOfNetRentalIncome || 0;

      const grossAnnualRentalIncome = grossMonthlyRentalIncome * 12;
      const annualVacancyPercent = annualVacancyPercentage / 100;
      const netRentalIncome = grossAnnualRentalIncome + ((grossAnnualRentalIncome * annualVacancyPercent) * -1);

      return (percentOfNetRentalIncome / 100) * netRentalIncome;
    },
    getTotalOperatingExpenses: state => () => {
      const grossMonthlyIncome = state.rentalIncome.grossMonthlyRentalIncome || 0;
      const annualVacancyPercentage = state.rentalIncome.annualVacancyPercentage || 0;
      const realEstateTaxes = state.annualOperatingExpenses.realEstateTaxes || 0;
      const insurance = state.annualOperatingExpenses.insurance || 0;
      const waterAndSewer = state.annualOperatingExpenses.waterAndSewer || 0;
      const publicElectric = state.annualOperatingExpenses.publicElectric || 0;
      const otherExpenses = state.annualOperatingExpenses.otherExpenses || 0;
      const percentOfNetRentalIncome = state.annualOperatingExpenses.percentOfNetRentalIncome || 0;


      const grossAnnualRentalIncome = grossMonthlyIncome * 12;
      const annualVacancyPercent = annualVacancyPercentage / 100;
      const netRentalIncome = grossAnnualRentalIncome + ((grossAnnualRentalIncome * annualVacancyPercent) * -1);

      const result = realEstateTaxes + insurance + waterAndSewer + publicElectric + otherExpenses +
        (percentOfNetRentalIncome / 100) * netRentalIncome;

      return result;
    },
    getPurchasePrice: state => () => {
      return state.purchaseInfo.purchasePrice;
    },
    getPurchaseClosingCosts: state => () => {
      return state.purchaseInfo.purchaseClosingCosts;
    },
    getEstimatedRenovationCost: state => () => {
      return state.purchaseInfo.estimatedRenovationCost;
    },
    getDownPayment: state => () => {
      return state.financingCosts.downPayment;
    },
    getPointsToLender: state => () => {
      return state.financingCosts.pointsToLender;
    },
    getInterestRate: state => () => {
      return state.financingCosts.interestRate;
    },
    getAmortizationYears: state => () => {
      return state.financingCosts.amortizationYears;
    },
    getTotalLoanAmount: state => () => {
      return state.financingCosts.totalLoanAmount;
    },
    getMonthlyPayment: state => () => {
      return state.financingCosts.monthlyPayment;
    },
  },
  mutations: {
    setStep(state, step) {
      state.step = step;
    },
    setStreetAddress(state, data) {
      state.address.street = data;
    },
    setCityAddress(state, data) {
      state.address.city = data;
    },
    setStateAddress(state, data) {
      state.address.state = data;
    },
    setZipAddress(state, data) {
      state.address.zip = data;
    },
    setNumberOfUnits(state, data) {
      state.details.numberOfUnits = data;
    },
    setCurrentStatus(state, data) {
      state.details.currentStatus = data;
    },
    setClosingDate(state, data) {
      state.details.closingDate = data;
    },
    setEstimatedValue(state, data) {
      state.details.estimatedValue = data;
    },
    setMarketCapRate(state, data) {
      state.details.marketCapRate = data;
    },
    setGrossMonthlyRentalIncome(state, data) {
      state.rentalIncome.grossMonthlyRentalIncome = data;
    },
    setAnnualVacancyPercentage(state, data) {
      state.rentalIncome.annualVacancyPercentage = data;
    },
    setRealEstateTaxes(state, data) {
      state.annualOperatingExpenses.realEstateTaxes = data;
    },
    setInsurance(state, data) {
      state.annualOperatingExpenses.insurance = data;
    },
    setWaterAndSewer(state, data) {
      state.annualOperatingExpenses.waterAndSewer = data;
    },
    setPublicElectric(state, data) {
      state.annualOperatingExpenses.publicElectric = data;
    },
    setOtherExpenses(state, data) {
      state.annualOperatingExpenses.otherExpenses = data;
    },
    setPercentOfNetRentalIncome(state, data) {
      state.annualOperatingExpenses.percentOfNetRentalIncome = data;
    },
    setPurchasePrice(state, data) {
      state.purchaseInfo.purchasePrice = data;
    },
    setPurchaseClosingCosts(state, data) {
      state.purchaseInfo.purchaseClosingCosts = data;
    },
    setEstimatedRenovationCost(state, data) {
      state.purchaseInfo.estimatedRenovationCost = data;
    },
    setDownPayment(state, data) {
      state.financingCosts.downPayment = data;
    },
    setPointsToLender(state, data) {
      state.financingCosts.pointsToLender = data;
    },
    setInterestRate(state, data) {
      state.financingCosts.interestRate = data;
    },
    setAmortizationYears(state, data) {
      state.financingCosts.amortizationYears = data;
    },
    setTotalLoanAmount(state, data) {
      state.financingCosts.totalLoanAmount = data;
    },
    setMonthlyPayment(state, data) {
      state.financingCosts.monthlyPayment = data;
    }
  },
  actions: {
    setStep({commit}, step) {
      commit('setStep', step);
    },
    setStreetAddress({commit}, data) {
      commit('setStreetAddress', data);
    },
    setCityAddress({commit}, data) {
      commit('setCityAddress', data);
    },
    setStateAddress({commit}, data) {
      commit('setStateAddress', data);
    },
    setZipAddress({commit}, data) {
      commit('setZipAddress', data);
    },
    setNumberOfUnits({commit}, data) {
      commit('setNumberOfUnits', data);
    },
    setCurrentStatus({commit}, data) {
      commit('setCurrentStatus', data);
    },
    setClosingDate({commit}, data) {
      commit('setClosingDate', data);
    },
    setEstimatedValue({commit}, data) {
      commit('setEstimatedValue', data);
    },
    setMarketCapRate({commit}, data) {
      commit('setMarketCapRate', data);
    },
    setGrossMonthlyRentalIncome({commit}, data) {
      commit('setGrossMonthlyRentalIncome', data);
    },
    setAnnualVacancyPercentage({commit}, data) {
      commit('setAnnualVacancyPercentage', data);
    },
    setRealEstateTaxes({commit}, data) {
      commit('setRealEstateTaxes', data);
    },
    setInsurance({commit}, data) {
      commit('setInsurance', data);
    },
    setWaterAndSewer({commit}, data) {
      commit('setWaterAndSewer', data);
    },
    setPublicElectric({commit}, data) {
      commit('setPublicElectric', data);
    },
    setOtherExpenses({commit}, data) {
      commit('setOtherExpenses', data);
    },
    setPercentOfNetRentalIncome({commit}, data) {
      commit('setPercentOfNetRentalIncome', data);
    },
    setPurchasePrice({commit}, data) {
      commit('setPurchasePrice', data);
    },
    setPurchaseClosingCosts({commit}, data) {
      commit('setPurchaseClosingCosts', data);
    },
    setEstimatedRenovationCost({commit}, data) {
      commit('setEstimatedRenovationCost', data);
    },
    setDownPayment({commit}, data) {
      commit('setDownPayment', data);
    },
    setPointsToLender({commit}, data) {
      commit('setPointsToLender', data);
    },
    setInterestRate({commit}, data) {
      commit('setInterestRate', data);
    },
    setAmortizationYears({commit}, data) {
      commit('setAmortizationYears', data);
    },
    setTotalLoanAmount({commit}, data) {
      commit('setTotalLoanAmount', data);
    },
    setMonthlyPayment({commit}, data) {
      commit('setMonthlyPayment', data);
    },
  }
});
