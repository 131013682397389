<template>
  <v-row>
    <v-card 
      max-width="600"
      class="ma-6"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="realEstateTaxes"
                label="Real Estate Taxes"
                type="number"
                prefix="$"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="insurance"
                label="Insurance"
                type="number"
                value="0"
                prefix="$"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="waterAndSewer"
                label="Water & Sewer"
                type="number"
                value="0"
                prefix="$"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="publicElectric"
                label="Public Electric"
                type="number"
                value="0"
                prefix="$"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="otherExpenses"
                label="Other Expenses"
                type="number"
                value="0"
                prefix="$"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="percentOfNetRentalIncome"
                label="% of Net Rental Income"
                type="number"
                value="0"
                prefix="%"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="repairsAndMaintenance"
                label="Repairs and Maintenance"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="totalOperatingExpenses"
                label="Total Operating Expenses"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"                
            >
              <v-text-field
                v-model="netOperatingIncome"
                label="Net Operating Income"
                prefix="$"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="setStep(5)"
        >
          Continue
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  computed: {
    realEstateTaxes: {
      get() {
        return this.$store.getters.getRealEstateTaxes();
      },
      set(value) {
        this.$store.commit('setRealEstateTaxes', parseFloat(value));
      }
    },
    insurance: {
      get() {
        return this.$store.getters.getInsurance();
      },
      set(value) {
        this.$store.commit('setInsurance', parseFloat(value));
      }
    },
    waterAndSewer: {
      get() {
        return this.$store.getters.getWaterAndSewer();
      },
      set(value) {
        this.$store.commit('setWaterAndSewer', parseFloat(value));
      }
    },
    publicElectric: {
      get() {
        return this.$store.getters.getPublicElectric();
      },
      set(value) {
        this.$store.commit('setPublicElectric', parseFloat(value));
      }
    },
    otherExpenses: {
      get() {
        return this.$store.getters.getOtherExpenses();
      },
      set(value) {
        this.$store.commit('setOtherExpenses', parseFloat(value));
      }
    },
    percentOfNetRentalIncome: {
      get() {
        return this.$store.getters.getPercentOfNetRentalIncome();
      },
      set(value) {
        this.$store.commit('setPercentOfNetRentalIncome', parseFloat(value));
      }
    },
    repairsAndMaintenance: {
      get() {
        return this.$store.getters.getRepairsAndMaintenance();
      }
    },
    totalOperatingExpenses: {
      get() {
        return this.$store.getters.getTotalOperatingExpenses();
      }
    },
    netOperatingIncome: {
      get() {
        return this.$store.getters.getRentalIncome() - 
          this.$store.getters.getTotalOperatingExpenses();
      }
    }
  },
  methods: {
    setStep: function(step) {
      this.$store.commit('setStep', step);
    },
  },
}
</script>

<style scoped>

</style>