<template>
  <v-row>
    <v-card 
      max-width="600"
      class="ma-6"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="getStreetAddress"
                label="Street Address*"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="getCityAddress"
                label="City*"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="getStateAddress"
                label="State*"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="getZipAddress"
                label="Zipcode*"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="setStep(2)"
        >
          Continue
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>

export default {
  data() {
    return {}
  },
  computed: {
    getStreetAddress: {
      set(street) {
        this.$store.commit('setStreetAddress', street);
      },
      get() {
        return this.$store.getters.getStreetAddress();
      }
    },
    getCityAddress: {
      set(city) {
        this.$store.commit('setCityAddress', city);
      },
      get() {
        return this.$store.getters.getCityAddress();
      }
    },
    getStateAddress: {
      set(state) {
        this.$store.commit('setStateAddress', state);
      },
      get() {
        return this.$store.getters.getStateAddress();
      }
    },
    getZipAddress: {
      set(zip) {
        this.$store.commit('setZipAddress', zip);
      },
      get() {
        return this.$store.getters.getZipAddress();
      }
    }
  },
  methods: {
    setStep: function(step) {
      this.$store.commit('setStep', step);
    },
  },
}
</script>

<style scoped>

</style>