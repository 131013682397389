<template>
  <div>
    <v-stepper-step
      :complete="getStep > step"
      :step="step"
    >
      <v-btn text  @click="setStep(step)">
        Rental Income
      </v-btn>
    </v-stepper-step>

    <v-stepper-content :step="step">
      <dialog-rental-income></dialog-rental-income>
    </v-stepper-content>
    
  </div>
</template>

<script>

import DialogRentalIncome from './DialogRentalIncome';

export default {
  props: ['step'],
  components: {
    DialogRentalIncome,
  },
  data() {
    return {
    }
  },
  computed: {
    getStep: function() {
      return this.$store.getters.getStep();
    }
  },
  methods: {
    setStep: function(step) {
      this.$store.commit('setStep', step);
    }
  }
}
</script>

<style scoped>

</style>