<template>
  <div>
    <v-stepper-step
      :complete="getStep > step"
      :step="step"
    >
      <v-btn text @click="setStep(step)">
        Property Address
      </v-btn>
    </v-stepper-step>

    <v-stepper-content :step="step">
      <dialog-property-address></dialog-property-address>
    </v-stepper-content>
  </div>
</template>

<script>

import DialogPropertyAddress from './DialogPropertyAddress';

export default {
  props: ['step'],
  components: {
    DialogPropertyAddress
  },
  data() {
    return {
      activate: true,
    }
  },
  computed: {
    getStep: function() {
      return this.$store.getters.getStep();
    },
    dialog: {
      get() {
        return this.activate;
      },
      set() {
        this.activate = !this.activate;
      }
    }
  },
  methods: {
    setStep: function(step) {
      this.$store.commit('setStep', step);
    },
  }
}
</script>

<style scoped>

</style>